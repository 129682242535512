var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'EducationalGroupAttendanceInfo',"size":"xl","headerText":_vm.$t('EducationalGroupAttendances.data'),"headerIcon":_vm.educationalGroupAttendance.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.fullCode,"title":_vm.$t('EducationalGroupAttendances.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.attendanceTypeNameCurrent,"title":_vm.$t('EducationalGroupAttendances.status'),"imgName":'EducationalGroupAttendances.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.educationalGroupAttendance.attendFromDate,
          _vm.educationalGroupAttendance.attendFromTime
        ),"title":_vm.$t('from'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
          _vm.educationalGroupAttendance.attendToDate,
          _vm.educationalGroupAttendance.attendToTime
        ),"title":_vm.$t('to'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.durationCurrent,"title":_vm.$t('general.duration'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.educationalGroupAttendanceNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'}}),_c('div',{staticClass:"my-card col-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("Users.data")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.userInfoData.fullCode,"title":_vm.$t('Users.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.userInfoData.userNameCurrent,"title":_vm.$t('Users.name'),"imgName":'users.svg'}})],1)]),_c('div',{staticClass:"my-card col-12"},[_c('span',{staticClass:"my-card-title"},[_vm._v(_vm._s(_vm.$t("EducationalScheduleTimes.data")))]),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalGroupAttendance.educationalScheduleTimeInfoData
              .educationalScheduleTimeNameCurrent,"title":_vm.$t('EducationalScheduleTimes.name'),"imgName":'EducationalScheduleTimes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalGroupAttendance
              .alternativeEducationalScheduleTimeInfoData
              .educationalScheduleTimeNameCurrent,"title":_vm.$t('EducationalScheduleTimes.alternative'),"imgName":'EducationalScheduleTimes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.educationalScheduleTimeInfoData
              .fullCode,"title":_vm.$t('general.code'),"imgName":'code.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalGroupAttendance.educationalScheduleTimeInfoData
              .appointmentTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.AppointmentTypesName'),"imgName":'AppointmentTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
              _vm.educationalGroupAttendance.educationalScheduleTimeInfoData
                .dateTimeFromDate,
              _vm.educationalGroupAttendance.educationalScheduleTimeInfoData
                .dateTimeFromTime
            ),"title":_vm.$t('from'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
              _vm.educationalGroupAttendance.educationalScheduleTimeInfoData
                .dateTimeToDate,
              _vm.educationalGroupAttendance.educationalScheduleTimeInfoData
                .dateTimeToTime
            ),"title":_vm.$t('to'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.educationalGroupAttendance.educationalScheduleTimeInfoData
              .durationCurrent,"title":_vm.$t('general.duration'),"imgName":'dateAndTime.svg'}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }